<template>
  <div ref="main" class="m-main-wrap" v-loading="loadList">
    <div ref="topDom">
      <!-- 搜索 start -->
      <div class="flex-l lhx40">
        <el-input
          class="w200 mr10 mb20"
          placeholder="请输入商品名稱/ID"
          clearable
          v-model="filters.pname"
          @clear="search"
          @keyup.enter.native="search"
        ></el-input>
        <el-input
          class="w200 mr10 mb20"
          placeholder="请输入用戶名稱/ID"
          clearable
          v-model="filters.uname"
          @clear="search"
          @keyup.enter.native="search"
        ></el-input>
        <el-select
          class="w200 mr10 mb20"
          v-model="filters.rid"
          filterable
          placeholder="請選擇商品分類"
          clearable
          @clear="search"
          @keyup.enter.native="search"
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <div>
          <el-button type="primary" @click="search">搜尋</el-button>
        </div>
      </div>
    </div>
    <!-- 内部侧边栏 -->
    <el-container>
      <el-main>
        <!-- 表格 -->
        <el-table
          :data="tableData.data"
          style="width: 100%"
          :height="tableHeight"
        >
          <el-table-column type="expand">
            <template slot-scope="props">
              <el-form label-position="top" inline>
                <el-form-item label="用戶ID" class="mr20">
                  <template>
                    <span>
                      <a
                        class="el-link el-link--primary"
                        target="_blank"
                        :href="
                          constants.clientUrl +
                            '/userDetails?userId=' +
                            props.row.tuid
                        "
                        >{{ props.row.uid }}</a
                      >
                    </span>
                  </template>
                </el-form-item>
                <el-form-item label="用戶名稱">
                  <span>
                    <a
                      class="el-link el-link--primary"
                      target="_blank"
                      :href="
                          constants.clientUrl +
                            '/userDetails?userId=' +
                            props.row.tuid
                        "
                    >
                      {{ props.row.nickname }}
                    </a>
                  </span>
                </el-form-item>
                <el-form-item label="訂單ID">
                  <template>{{ props.row.order_no }}</template>
                </el-form-item>
                <el-form-item label="商戶訂單ID">
                  <template>{{ props.row.transaction_id }}</template>
                </el-form-item>
                <el-form-item label="支付金額（HK$）">
                  <template>{{ props.row.price }}</template>
                </el-form-item>
                <el-form-item label="購買曝光量">
                  <template>{{ props.row.number }}</template>
                </el-form-item>
                <el-form-item label="推介時間（天）" width="100">
                  <template>{{ props.row.days }}</template>
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column width="60" label="NO.">
            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>
          <el-table-column label="商品ID">
            <template slot-scope="scope">{{ scope.row.randname }}</template>
          </el-table-column>
          <el-table-column label="商品信息" width="260">
            <a 
            :href="constants.clientUrl+'/goodsDetails?id='+scope.row.pid"
            target="_blank" slot-scope="scope">
              <div class="flex-l flex-mid">
                <div>
                  <el-image
                    v-if="scope.row.image"
                    style="width: 60px; height: 60px"
                    :src="constants.imgUrl + scope.row.image"
                    fit="contain"
                  ></el-image>
                </div>
                <div class="w200 ml20">
                  <div>{{ scope.row.title }}</div>
                  <div class="flex-bt color-999">
                    <span>{{ scope.row.nid }}</span>
                    <span>HK${{ scope.row.price }}</span>
                  </div>
                </div>
              </div>
            </a>
          </el-table-column>
          <el-table-column
            label="预期曝光量"
            width="120"
            prop="e_number"
            sortable
          >
            <template slot-scope="scope">
              {{ scope.row.e_number }}
            </template>
          </el-table-column>
          <el-table-column
            width="120"
            label="獲得曝光量"
            prop="u_number"
            sortable
          ></el-table-column>
          <el-table-column
            width="120"
            label="剩餘曝光量"
            prop="r_number"
            sortable
          ></el-table-column>
          <el-table-column label="推介時間" width="200">
            <template slot-scope="scope">
              {{ scope.row.start_time | formatDate }} -
              {{ scope.row.end_time | formatDate }}
            </template>
          </el-table-column>
          <el-table-column label="推介狀態">
            <template slot-scope="scope">
              <!-- "state": 1,//状态 1.推介中 2.推介完成，3.推介失效 -->
              <el-tag v-if="scope.row.state == 1">推介中</el-tag>
              <el-tag type="success" v-if="scope.row.state == 2"
                >推介完成</el-tag
              >
              <el-tag type="danger" v-if="scope.row.state == 3"
                >推介失效</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right">
            <template slot-scope="scope">
              <el-link
                type="primary"
                class="mr10"
                @click="openEditDialog(scope.$index)"
                >编辑</el-link
              >
              <el-link
                type="danger"
                @click="stopPayment(scope.row.id)"
                v-if="scope.row.state == 1"
                >停止</el-link
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 ｜ 批量移动-->
        <div ref="btmDom" class="flex-bt flex-mid pb10 pt10">
          <el-pagination
            background
            hide-on-single-page
            :page-size="tableData.per_page"
            :current-page="tableData.current_page"
            :total="tableData.total"
            @current-change="changePage"
          ></el-pagination>
        </div>
      </el-main>
      <editPaymentDialog
        ref="editPay"
        type="edit"
        :show="showDialog"
        @close="showDialog = false"
        @confirm="editPayment"
        :item="curItem"
      ></editPaymentDialog>
    </el-container>
  </div>
</template>
<script>
import { calTableHeight } from '@/common/tool'
import editPaymentDialog from './components/editPayment.vue'
export default {
  components: { editPaymentDialog },
  data() {
    return {
      loadList: false,
      showDialog: false,
      options: [],
      curItem: {},
      curIndex: -1,
      filters: {
        pname: '',
        uname: '',
        rid: '',
      },
      tableData: {
        data: [],
        per_page: 20,
        current_page: 1,
        total: 0,
      },
      tableHeight: 100, //表格高度
    }
  },
  created() {
    this.getDegrees()
    this.getDataList()
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = calTableHeight(this)
    })
  },
  methods: {
    async getDegrees() {
      let res = await this.api.goods.getGoodsDegrees()
      if (res && res.code == 0) {
        res.data = res.data ? res.data : {}
        // this.degrees = res.data.newold
        this.options = res.data.rule
      }
    },
    async getDataList(page) {
      this.loadList = true
      let res = await this.api.goods.getPayments({
        page,
        ...this.filters,
      })
      if (res && res.code == 0) {
        this.tableData = res.data
      }
      this.loadList = false
    },
    openEditDialog(index) {
      this.curIndex = index
      this.curItem = this.tableData.data[index]
      this.showDialog = true
    },
    async editPayment(data) {
      let res = await this.api.goods.editPayment(data)
      if (res && res.code == 0) {
        this.$message({
          message: '保存成功',
          type: 'success',
        })
        this.showDialog = false
        this.getDataList(this.tableData.current_page)
      }
      this.$refs['editPay'].saving = false
    },
    /**
     * @method: resetFilters 重置筛选条件
     */
    resetFilters() {
      this.filters = {
        pname: '',
        uname: '',
        rid: '',
      }
    },
    search() {
      this.getDataList()
    },
    changePage(pageNum) {
      this.getDataList(pageNum)
    },
    async stopPayment(id) {
      let res = await this.api.goods.stopPayment(id)
      if (res && res.code == 0) {
        this.$message({
          message: '已停止',
          type: 'success',
        })
        this.getDataList(this.tableData.current_page)
      }
    },
  },
}
</script>

<style scoped>
/deep/.el-form-item__label {
  color: #999;
}
/deep/.el-form--inline .el-form-item {
  padding-right: 20px;
}
/deep/.el-table__fixed-body-wrapper {
  top: 59px !important;
}
</style>
