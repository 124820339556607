<!--
 * @Description: 
 * @Version: 1.0
 * @Author: YaoDongxin
 * @Date: 2022-11-08 18:19:45
 * @LastEditors: YaoDongxin
 * @LastEditTime: 2022-12-02 17:25:34
-->
<template>
  <el-dialog
    :title="type == 'create' ? '新增' : '編輯' + title"
    :visible.sync="showDialog"
    width="400"
    :modal-append-to-body="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    @close="close"
  >
    <div v-loading="loading">
      <el-form label-width="100px" :model="form">
        <el-form-item label="推介时间">
          <el-date-picker
            v-model="time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="推介開始日期"
            end-placeholder="推介結束日期"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="预期曝光量">
          <el-input-number
            v-model="form.e_number"
            label="预期曝光量"
          ></el-input-number>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close()">取 消</el-button>
      <el-button type="primary" @click="confirm()" :loading="saving"
        >保 存</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'create',
    },
    item: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      saving: false,
      time: [],
      loading: false,
      title: '編輯',
      showDialog: false,
      form: {
        start_time: '',
        end_time: '',
        e_number: '',
      },
    }
  },
  watch: {
    show(val) {
      this.showDialog = val
      // 打开弹窗的时候请求数据
      if (val) {
        if (this.type == 'edit') {
          this.form = { ...this.form, ...this.item }
          this.time = [this.form.start_time * 1000, this.form.end_time * 1000]
        } else {
          this.form = {
            start_time: '',
            end_time: '',
            e_number: '',
          }
        }
      }
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    confirm() {
      if (Object.prototype.toString.call(this.time) == '[object Array]') {
        if (this.time.length > 0) {
          this.form.start_time = +this.time[0] / 1000
          this.form.end_time = +this.time[1] / 1000
        }
      }
      this.saving = true
      this.$emit('confirm', this.form)
    },
  },
}
</script>
<style scoped></style>
